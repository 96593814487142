import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_BROWSER_LANGUAGE } from './components/Dashboard/Common/Translate/atom';
import Backend from 'i18next-http-backend';

export const initLocale = i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: ['base', 'content'],
    defaultNS: 'content',
    fallbackNS: 'base',
    backend: {
      loadPath: '/locales/{{ns}}-{{lng}}.json',
      queryStringParams: { v: $APP_VERSION }
    },
    lng: localStorage.getItem('lang')?.toUpperCase() ?? DEFAULT_BROWSER_LANGUAGE,
    fallbackLng: 'US',
    returnNull: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });
