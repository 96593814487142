import { ComponentType, LazyExoticComponent, lazy, useEffect } from 'react';

const ReloadIfMissing = () => {
  useEffect(() => {
    console.warn(' >> new update - reload required');
    if (window.location.hostname !== 'localhost') {
      window.location.reload();
    }
  }, []);
  return null;
};

export function safeLazy<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): LazyExoticComponent<T> {
  return lazy(async () => {
    try {
      return await factory();
    } catch (e) {
      console.log(e);
      return {
        default: ReloadIfMissing as unknown as T
      };
    }
  });
}
