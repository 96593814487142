// remove unused localStorage items
localStorage.removeItem('no-service-check');
localStorage.removeItem('history-trace:limit');

if (localStorage.getItem('lang')?.toLowerCase() === 'en') {
  localStorage.removeItem('lang');
}

if ('serviceWorker' in navigator) {
  // unregister old services workers
  navigator.serviceWorker.getRegistrations().then(async (registrations) => {
    for (const registration of registrations) {
      if (!registration.scope?.includes('partytown')) {
        const active = registration.active;
        await registration.unregister();
        if (active) {
          window.location.reload();
        }
      }
    }
  });
}
