import { gql } from '@apollo/client';

export const manageResourceMutation = gql`
  mutation ($device: DeviceInput, $app: AppInput, $employee: EmployeeForm, $photo: String) {
    manageResource(device: $device, app: $app, employee: $employee, photo: $photo)
  }
`;

export const uploadItemsTranslation = gql`
  mutation ($file: String!) {
    uploadItemsTranslation(file: $file)
  }
`;

export const assignChainToSubscriptionMutation = gql`
  mutation ($chain: String!, $subscription: String!) {
    assignChainToSubscription(chainId: $chain, subscriptionId: $subscription)
  }
`;


export const markAsFinalProductMutation = gql`
  mutation ($id: String!, $enabled: Boolean!) {
    markAsFinalProduct(id: $id, enabled: $enabled)
  }
`;

export const duplicateItemsMutation = gql`
  mutation ($items: [String!]!, $copies: Int!) {
    duplicateItems(elements: $items, copies: $copies)
  }
`;

export const updateStripeKeysMutation = gql`
  mutation ($publicKey: String!, $privateKey: String!) {
    updateStripeKeys(publicKey: $publicKey, privateKey: $privateKey)
  }
`;

export const addMarketplaceShippingRateMutation = gql`
  mutation ($country: String!, $amount: Int!) {
    addMarketplaceShippingRate(country: $country, amount: $amount)
  }
`;

export const deleteMarketplaceShippingRateMutation = gql`
  mutation ($id: String!) {
    deleteMarketplaceShippingRate(id: $id)
  }
`;

export const marketplaceManageOrderMutation = gql`
  mutation ($id: String!) {
    marketplaceManageOrder(id: $id)
  }
`;

// export const updateMarketplaceShippingRateMutation = gql`
//   mutation ($id: String!, $amount: Int!) {
//     updateMarketplaceShippingRate(id: $id, amount: $amount)
//   }
// `;

export const qrCodeTelemetryMutation = gql`
  mutation ($id: String!, $coords: [Float!], $version: Int) {
    qrCodeTelemetry(id: $id, coords: $coords, version: $version)
  }
`;

export const uploadChainTranslationExcel = gql`
  mutation ($file: String!) {
    uploadChainTranslationExcel(file: $file)
  }
`;

export const contactMutation = gql`
  mutation ($name: String!, $mail: String!, $content: String!, $extra: [String!]!) {
    contact(name: $name, mail: $mail, content: $content, extra: $extra)
  }
`;

export const buyProductsMutation = gql`
  mutation ($id: String!, $quantity: Int!, $country: String!, $product: String) {
    buyProducts(id: $id, quantity: $quantity, country: $country, product: $product)
  }
`;

export const updateProductInventoryMutation = gql`
  mutation ($product: String!, $companyProduct: String!, $amount: Int!, $price: Float!) {
    updateProductInventory(
      product: $product
      companyProduct: $companyProduct
      amount: $amount
      price: $price
    )
  }
`;

export const deleteProductInventoryMutation = gql`
  mutation ($product: String!) {
    deleteProductInventory(product: $product)
  }
`;

export const updateCompanySocialMutation = gql`
  mutation ($id: String!, $social: CompanySocialInput!) {
    updateCompanySocial(id: $id, social: $social)
  }
`;

export const upsertCompanyPageMutation = gql`
  mutation (
    $lang: String
    $body: String
    $social: JSON
    $cover: String
    $logo: String
    $video: String
    $gallery: [String!]
    $languages: [String!]
    $signedUrl: String
  ) {
    upsertCompanyPage(
      lang: $lang
      body: $body
      social: $social
      video: $video
      coverImage: $cover
      logoImage: $logo
      gallery: $gallery
      languages: $languages
      signedUrl: $signedUrl
    )
  }
`;

export const updateCompanyInfoMutation = gql`
  mutation ($lang: String, $basicInfo: CompanyPageBasicInfoInput!, $info: CompanyPageInfoInput!) {
    updateCompanyInfo(lang: $lang, basicInfo: $basicInfo, info: $info)
  }
`;

export const upsertCompanyProductMutation = gql`
  mutation (
    $id: String
    $productInput: CompanyProductInput
    $lang: String
    $languages: [String!]
    $signedUrl: String
  ) {
    upsertCompanyProduct(
      id: $id
      productInput: $productInput
      lang: $lang
      languages: $languages
      signedUrl: $signedUrl
    )
  }
`;

export const handleProductCertificationMutation = gql`
  mutation (
    $id: String!
    $lang: String
    $document: String!
    $name: String
    $category: String
    $action: CertificationAction!
  ) {
    handleProductCertification(
      id: $id
      lang: $lang
      document: $document
      name: $name
      category: $category
      action: $action
    )
  }
`;

export const addCertificationMutation = gql`
  mutation (
    $id: String!
    $type: CertificationType!
    $document: String!
    $name: String
    $category: String
    $idx: Int!
    $lang: String
  ) {
    addCertification(
      id: $id
      type: $type
      document: $document
      name: $name
      category: $category
      idx: $idx
      lang: $lang
    )
  }
`;

export const updateCertificationLanguagesMutation = gql`
  mutation ($source: String!, $updates: [CertificationLanguages!]!) {
    updateCertificationLanguages(updates: $updates, source: $source)
  }
`;

export const sortCertificationsMutation = gql`
  mutation ($source: String!, $certs: [String!]!) {
    sortCertifications(certs: $certs, source: $source)
  }
`;

export const deleteCertificationMutation = gql`
  mutation ($source: String!, $document: String!) {
    deleteCertification(source: $source, document: $document)
  }
`;

export const handleCompanyCertificationMutation = gql`
  mutation (
    $lang: String
    $document: String!
    $name: String
    $action: CertificationAction!
    $category: String
  ) {
    handleCompanyCertification(
      lang: $lang
      document: $document
      name: $name
      action: $action
      category: $category
    )
  }
`;

export const handleProductPublishMutation = gql`
  mutation ($id: String, $published: Boolean) {
    handleProductPublish(id: $id, published: $published)
  }
`;

export const logMutation = gql`
  mutation (
    $message: String
    $stack: String
    $componentStack: String
    $localStorage: String
    $sessionStorage: String
    $location: String
    $userAgent: String
    $cookies: String
    $time: String
    $lang: String
  ) {
    log(
      message: $message
      stack: $stack
      componentStack: $componentStack
      localStorage: $localStorage
      sessionStorage: $sessionStorage
      location: $location
      userAgent: $userAgent
      cookies: $cookies
      time: $time
      lang: $lang
    )
  }
`;

export const deleteCompanyProductMutation = gql`
  mutation ($id: String!) {
    deleteCompanyProduct(productId: $id)
  }
`;

export const changeResourceStatusMutation = gql`
  mutation ($type: ResourceType!, $id: String!, $enabled: Boolean!) {
    changeResourceStatus(type: $type, enabled: $enabled, id: $id)
  }
`;

export const removeResourcesMutation = gql`
  mutation ($type: ResourceType!, $resources: [String!]!) {
    removeResources(type: $type, resources: $resources)
  }
`;

export const refreshResourcePasswordMutation = gql`
  mutation ($id: String!, $type: ResourceType!) {
    refreshCredentials(id: $id, type: $type) {
      access_token
      refresh_token
      expires_at
      refresh_expires_at
    }
  }
`;

export const updateResourcePermissionsMutation = gql`
  mutation ($type: ResourceType!, $id: String!, $permissions: [String!]!, $add: Boolean!) {
    updateResourcePermissions(type: $type, id: $id, permissions: $permissions, add: $add)
  }
`;

export const loginAsUserMutation = gql`
  mutation ($id: String!) {
    loginAsUser(id: $id)
  }
`;

export const changeUserStatusMutation = gql`
  mutation ($id: String!) {
    changeUserStatus(id: $id)
  }
`;

export const createQRCodeAssetMutation = gql`
  mutation ($assetInput: QRCodeAssetInput!) {
    createQRCodeAsset(assetInput: $assetInput)
  }
`;

export const removeQRCodeAssetMutation = gql`
  mutation ($id: String!) {
    removeQRCodeAsset(id: $id)
  }
`;

export const createChainMutation = gql`
  mutation ($name: String!) {
    createChain(name: $name)
  }
`;

export const renameChainMutation = gql`
  mutation ($id: String!, $name: String!) {
    renameChain(id: $id, name: $name)
  }
`;

export const updateChainCoverMutation = gql`
  mutation ($id: String!, $cover: String!) {
    updateChainCover(id: $id, cover: $cover)
  }
`;

export const requestUploadSignedURLMutation = gql`
  mutation ($operation: String!, $count: Int!, $meta: String) {
    requestUploadSignedURL(operation: $operation, count: $count, meta: $meta)
  }
`;

export const updateChainMutation = gql`
  mutation (
    $ontologyChainInput: UpdateOntologyChainInput
    $ontologyChainLanguagesInput: UpdateOntologyLanguagesInput
  ) {
    updateChain(
      updateOntologyChainInput: $ontologyChainInput
      updateOntologyChainLanguagesInput: $ontologyChainLanguagesInput
    )
  }
`;

export const endTrialNowMutation = gql`
  mutation {
    endTrialNow
  }
`;

export const cloneChainMutation = gql`
  mutation ($id: String!) {
    cloneChain(id: $id)
  }
`;

export const linkQRCodeToItemMutation = gql`
  mutation ($productId: String!, $qrCodeId: String!) {
    linkQRCodeToItem(productId: $productId, qrCodeId: $qrCodeId)
  }
`;

export const changeUserVisibilityForChainMutation = gql`
  mutation ($id: String!, $accessBy: [String!]!, $defaultAccess: Boolean) {
    changeUserVisibilityForChain(chainId: $id, accessBy: $accessBy, defaultAccess: $defaultAccess)
  }
`;

export const createSettingMutation = gql`
  mutation ($settings: UserSettingsInput!) {
    updateSettings(settings: $settings) {
      success
    }
  }
`;

export const uploadToTheBlockchainMutation = gql`
  mutation ($uri: [String!]!) {
    uploadToTheBlockchain(uri: $uri)
  }
`;

export const setHomeFeaturedMutation = gql`
  mutation ($content: String!, $signedUrl: String, $lang: String) {
    setHomeFeatured(content: $content, signedUrl: $signedUrl, lang: $lang)
  }
`;

export const refreshCompanyTranslationsMutation = gql`
  mutation {
    refreshCompanyTranslations
  }
`;

export const refreshChainTranslationsMutation = gql`
  mutation ($id: String!) {
    refreshChainTranslations(id: $id)
  }
`;

export const refreshItemsTranslationsMutation = gql`
  mutation ($items: [String!]!) {
    refreshItemsTranslations(items: $items)
  }
`;

export const refreshProductTranslationsMutation = gql`
  mutation ($id: String!) {
    refreshProductTranslations(id: $id)
  }
`;

export const deleteItemsMutation = gql`
  mutation ($uri: [String!]!) {
    deleteItems(uri: $uri)
  }
`;

export const addItemMutation = gql`
  mutation (
    $data: JSON!
    $id: String
    $schema: String!
    $chain: String
    $copies: Int
    $comment: String
    $translations: JSON
    $signedUrl: String
  ) {
    addItem(
      chain: $chain
      copies: $copies
      data: $data
      id: $id
      schema: $schema
      updateComment: $comment
      translations: $translations
      signedUrl: $signedUrl
    )
  }
`;

export const requestValidationMutation = gql`
  mutation ($id: String!) {
    requestValidation(id: $id)
  }
`;

export const updateCompaniesOrderMutation = gql`
  mutation ($ids: [String!]!) {
    updateCompaniesOrder(ids: $ids)
  }
`;

export const updateCompanyProductsOrderMutation = gql`
  mutation ($ids: [String!]!) {
    updateCompanyProductsOrder(ids: $ids)
  }
`;

export const updateCompanyVisibilityMutation = gql`
  mutation ($id: String!, $visible: Boolean!) {
    updateCompanyPageVisibility(id: $id, visible: $visible)
  }
`;

export const removeChainMutation = gql`
  mutation ($id: String!) {
    removeChain(id: $id)
  }
`;

export const updateProfileMutation = gql`
  mutation ($update: UpdateProfileInput!, $photo: String) {
    updateProfile(update: $update, photo: $photo)
  }
`;

export const logoutMutation = gql`
  mutation {
    logout
  }
`;

export const printQRCodeMutation = gql`
  mutation ($id: String!, $opacity: Float!, $page: Int, $position: JSON!, $size: Int!) {
    printQRCode(id: $id, opacity: $opacity, page: $page, size: $size, position: $position)
  }
`;

export const loginMutation = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const signupCustomerMutation = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $recaptcha: String!
  ) {
    signupCustomer(
      user: { firstName: $firstName, lastName: $lastName, email: $email, password: $password }
      recaptcha: $recaptcha
    ) {
      success
      data
    }
  }
`;

export const updateItemSharingMutation = gql`
  mutation ($uri: [String!]!, $share: Boolean!, $shareWith: String!) {
    updateItemSharing(uri: $uri, share: $share, shareWith: $shareWith)
  }
`;

export const signupMutation = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $recaptcha: String!
    $type: SignupType!
  ) {
    signup(
      user: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        type: $type
      }
      recaptcha: $recaptcha
    ) {
      success
      data
    }
  }
`;

export const recoverCustomerMutation = gql`
  mutation ($email: String!, $recaptcha: String!) {
    recoverCustomer(user: { email: $email }, recaptcha: $recaptcha) {
      success
    }
  }
`;

export const changePasswordMutation = gql`
  mutation ($hash: String!, $email: String!, $recaptcha: String!, $password: String!) {
    changePassword(
      hash: $hash
      user: { email: $email, password: $password }
      recaptcha: $recaptcha
    ) {
      success
    }
  }
`;

export const validateUserMutation = gql`
  mutation ($hash: String!, $email: String!) {
    validateUser(hash: $hash, email: $email) {
      success
      data
    }
  }
`;

export const subscribeMutation = gql`
  mutation ($plans: [PurchasePlan!]!) {
    subscribe(plans: $plans)
  }
`;

// Document Trace
export const insertDocumentMutation = gql`
  mutation (
    $services: [DocumentsServices!]!
    $id: String
    $hashes: [HashFileInput!]!
    $signedUrl: String
    $visibility: DocumentVisibility
  ) {
    notarize(
      id: $id
      services: $services
      hashes: $hashes
      signedUrl: $signedUrl
      visibility: $visibility
    ) {
      hash
      filename
      _id
      uri
    }
  }
`;

export const addItemsExcelMutation = gql`
  mutation ($file: String!, $chain: String) {
    addItemsExcel(file: $file, chain: $chain)
  }
`;

export const removeClientMutation = gql`
  mutation ($id: String!) {
    removeClient(id: $id)
  }
`;

export const unsubscribePlansMutation = gql`
  mutation ($id: String!) {
    unsubscribePlans(id: $id)
  }
`;

export const editOptionMutation = gql`
  mutation ($id: String!, $planId: String!, $quantity: Int!) {
    editOption(id: $id, planId: $planId, quantity: $quantity)
  }
`;

export const editPlanMutation = gql`
  mutation (
    $id: String
    $instanceId: String
    $itemCartId: String
    $action: CartAction!
    $chain: String
    $sector: String
  ) {
    editPlan(
      id: $id
      instanceId: $instanceId
      action: $action
      itemCartId: $itemCartId
      chain: $chain
      sector: $sector
    )
  }
`;

export const setPlatformSettingsMutation = gql`
  mutation ($maintenance: Boolean!) {
    setPlatformSettings(maintenance: $maintenance)
  }
`;

export const addClientNodeMutation = gql`
  mutation {
    addClientNode
  }
`;

export const removeClientNodeMutation = gql`
  mutation ($id: String!) {
    removeClientNode(id: $id)
  }
`;

export const readAllNotificationsMutation = gql`
  mutation {
    readAllNotifications
  }
`;

export const removeAllNotificationsMutation = gql`
  mutation {
    removeAllNotifications
  }
`;

export const removeNotificationMutation = gql`
  mutation ($id: String!) {
    removeNotification(id: $id)
  }
`;

export const readNotificationMutation = gql`
  mutation ($id: String!) {
    readNotification(id: $id)
  }
`;
