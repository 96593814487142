let broadcastChannel: BroadcastChannel;

if ('BroadcastChannel' in window) {
  broadcastChannel = new BroadcastChannel('events');
  broadcastChannel.onmessage = (ev) => {
    // reload page if logout broadcast is received
    if (ev.data === 'reload') {
      window.location.reload();
    }
  };
}

// broadcast any message to other tabs
export const broadcast = (message: string) => {
  broadcastChannel?.postMessage(message);
};
