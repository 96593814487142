import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  components: {
    LoadingOverlay: {
      defaultProps: {
        overlayOpacity: 0
      }
    },
    Button: {
      defaultProps: {
        radius: 'xl',
        size: 'sm'
      }
    },
    Anchor: {
      defaultProps: {
        color: 'accent.4'
      }
    },
    MultiSelect: {
      styles: {
        separatorLabel: {
          color: 'black !important'
        }
      }
    },
    Alert: {
      defaultProps: {
        radius: 'md'
      },
      styles: {
        title: {
          marginBottom: 0
        }
      }
    },
    Divider: {
      defaultProps: {
        color: 'gray.2'
      }
    },
    Paper: {
      defaultProps: {
        radius: 'md'
      }
    },
    ThemeIcon: {
      defaultProps: {
        variant: 'transparent'
      }
    },
    Tooltip: {
      defaultProps: {
        zIndex: 1009,
        withArrow: true,
        arrowSize: 8,
        position: 'bottom'
      }
    },
    ActionIcon: {
      defaultProps: {
        variant: 'transparent'
      }
    },
    Pagination: {
      defaultProps: {
        withControls: false,
        radius: 'xl'
      }
    },
    Group: {
      defaultProps: {
        gap: 'xs'
      }
    },
    Select: {
      defaultProps: {
        allowDeselect: false
      },
      styles: {
        separatorLabel: {
          fontWeight: 'bold',
          color: '#363636',
          textTransform: 'uppercase'
        },
        label: {
          marginBottom: 4,
          fontWeight: 'bold',
          color: '#565656'
        }
      }
    },
    Modal: {
      defaultProps: {
        centered: true
      },
      styles: {
        close: {
          borderRadius: 40
        },
        root: {
          zIndex: 1024
        },
        title: {
          fontWeight: 'bold'
        },
        header: {
          marginBottom: 4,
          paddingBottom: 0
        }
      }
    }
  },
  primaryColor: 'accent',
  primaryShade: 4,
  colors: {
    red: [
      '#ffe6e6',
      '#f7bebe',
      '#eb9595',
      '#e16b6c',
      '#d74242',
      '#bd2828',
      '#941e1e',
      '#6a1415',
      '#42090a',
      '#1d0000'
    ],
    accent: [
      'var(--accent100)',
      'var(--accent200)',
      'var(--accent300)',
      'var(--accent400)',
      'var(--accent)',
      'var(--accent600)',
      'var(--accent700)',
      'var(--accent800)',
      'var(--accent900)',
      'var(--accent1000)'
    ],
    dark: [
      '#393939',
      '#343434',
      '#2F2F2F',
      '#2B2B2B',
      '#272727',
      '#232323',
      '#202020',
      '#1C1C1C',
      '#1A1A1A',
      '#171717'
    ]
  }
};
