import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { detect } from 'detect-browser';
import ApolloLinkTimeout from 'apollo-link-timeout';

const timeoutLink = new ApolloLinkTimeout(60000);

const browser = detect();

const link = createHttpLink({
  uri: '/graphql',
  headers: {
    device: browser ? `${browser.name}/${browser.version}, ${browser.os}` : 'webapp'
  }
});

export const client = new ApolloClient({
  link: timeoutLink.concat(link),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore'
    },
    query: {
      // fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    }
  }
});
