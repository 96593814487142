import { client } from '@/graphql/client';
import { logMutation } from '@/graphql/mutation';
import StatusPage from '@/pages/StatusPage/StatusPage';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (window.location.hostname !== 'localhost') {
      client.mutate({
        mutation: logMutation,
        variables: {
          error: error.message,
          stack: error.stack,
          componentStack: errorInfo.componentStack,
          localStorage: JSON.stringify(localStorage),
          sessionStorage: JSON.stringify(sessionStorage),
          location: window.location.href,
          userAgent: navigator.userAgent,
          cookies: document.cookie,
          lang: navigator.language,
          time: new Date().toISOString()
        }
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      if (window.location.hostname !== 'localhost') {
        location.href = '/';
        return null;
      } else return <StatusPage type="500" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
