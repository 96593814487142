import { Button, Container, Group, Text, Title } from '@mantine/core';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { codes } from './StatusCode';
import classes from './StatusPage.module.css';

interface StatusPageProps {
  type: '404' | '500' | '503';
}

function StatusPage({ type }: StatusPageProps) {
  const navigate = useNavigate();

  const StatusCode = codes[type];

  return (
    <div className={classes.root}>
      <Container w="100%">
        <div>
          <StatusCode className={classes.image} />
          <div className={classes.content}>
            <Title className={classes.title}>{t(`${type}.title`)}</Title>
            <Text size="lg" className={classes.description}>
              {t(`${type}.content`)}
            </Text>
            <Group justify="center" gap="md">
              {type === '500' && (
                <Button size="md" variant="white" onClick={() => window.location.reload()}>
                  {t(`${type}.reload`)}
                </Button>
              )}
              {type === '500' ? (
                <Button
                  size="md"
                  variant="white"
                  onClick={() => {
                    document.cookie = '';
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    window.location.reload();
                  }}>
                  {t(`${type}.reset`)}
                </Button>
              ) : (
                <Button size="md" variant="white" onClick={() => navigate('/')}>
                  {t(`${type}.goBack`)}
                </Button>
              )}
            </Group>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default StatusPage;
