import { gql } from '@apollo/client';

export const hashCompletionsQuery = gql`
  query ($query: String!, $userOnly: Boolean!) {
    hashCompletions(query: $query, userOnly: $userOnly)
  }
`;

export const homeFeaturedQuery = gql`
  query ($lang: String) {
    homeFeatured(lang: $lang)
  }
`;

export const stripeKeysQuery = gql`
  query ($companyId: String) {
    stripeKeys(companyId: $companyId)
  }
`;

export const hasStripeKeysQuery = gql`
  query {
    hasStripeKeys
  }
`;

export const marketplaceShippingRatesQuery = gql`
  query {
    marketplaceShippingRates {
      country
      id
      amount
    }
  }
`;

export const marketplaceProductsQuery = gql`
  query ($product: String!) {
    marketplaceProducts(product: $product) {
      product
      label
      photo
      amount
      price
    }
  }
`;

export const marketplaceOrderQuery = gql`
  query ($id: String!) {
    marketplaceOrder(id: $id)
  }
`;

export const marketplaceOrdersQuery = gql`
  query ($filters: JSON, $after: String, $limit: Int) {
    marketplaceOrders(filters: $filters, after: $after, limit: $limit) {
      items {
        id
        title
        url
        status
        quantity
        createdAt
      }
      nextCursor
    }
  }
`;

export const companyShippingRatesQuery = gql`
  query ($id: String!) {
    companyShippingRates(id: $id)
  }
`;

export const documentQuery = gql`
  query ($id: String!) {
    document(id: $id) {
      _id
      createdAt
      data
      filename
      hash
      path
      appliedQRCodeData
      stored
      visibility
      transactions {
        transaction
        service
      }
      updatedAt
      uri
      userId
    }
  }
`;

export const getSharedDataQuery = gql`
  query {
    getSharedData
  }
`;

export const findLabelsByIdQuery = gql`
  query ($items: [String!]!) {
    findLabelsById(items: $items)
  }
`;

export const historyItemsQuery = gql`
  query (
    $type: ItemType!
    $page: Int!
    $limit: Int!
    $filters: [ItemsFilterInput!]!
    $lite: Boolean
  ) {
    historyItems(type: $type, page: $page, limit: $limit, filters: $filters, lite: $lite) {
      total
      items {
        id
        finalProduct
        label
        shared
        chain
        schema {
          id
          label
        }
        qrCodeId
        uploadedAt
        createdAt
        users {
          id
          name
          vat
          image
          sharedAt
        }
      }
    }
  }
`;

export const verifyNotarizationQuery = gql`
  query ($hashes: [String!]!) {
    verifyNotarization(hashes: $hashes, services: [SEEDSBIT]) {
      _id
      filename
      hash
      stored
      uri
      transactions {
        service
        transaction
      }
    }
  }
`;

export const historyFiltersQuery = gql`
  query {
    historyFilters {
      users {
        label
        value
        image
      }
      chains {
        label
        value
        items {
          process {
            label
            value
          }
          product {
            label
            value
          }
        }
      }
    }
  }
`;

export const itemWarningsCountQuery = gql`
  query {
    itemsWarningsCount
  }
`;

export const itemWarningsQuery = gql`
  query {
    itemsWarnings {
      id
      label
      type
    }
  }
`;

export const searchCompaniesQuery = gql`
  query ($q: String!, $items: [String!]!) {
    searchCompanies(q: $q, items: $items) {
      id
      name
      vat
    }
  }
`;

export const getNotificationsQuery = gql`
  query ($page: Int!, $limit: Int!, $after: String) {
    getNotifications(page: $page, limit: $limit, after: $after) {
      _id
      title
      type
      content
      read
      createdAt
      updatedAt
    }
  }
`;

export const companyPageQuery = gql`
  query ($id: String!, $lang: String) {
    companyPage(companyId: $id, lang: $lang) {
      mail
      website
      cover
      video
      logo
      gallery
      video
      isOwner
      languages
      body
      phone
      social
      shortDescription
      isOwner
      basicInfo {
        name
        category
        rea
        PEC
        ateco
        fiscalCode
        vatFiscalCode
        city
        country
        province
        region
        postalCode
        address
      }
    }
  }
`;

export const certificationsQuery = gql`
  query Certifications($id: String, $lang: String) {
    certifications(id: $id, lang: $lang) {
      name
      document
      category
      languages
    }
  }
`;

export const companyInfoQuery = gql`
  query ($companyId: String!, $lang: String) {
    companyPage(companyId: $companyId, lang: $lang) {
      basicInfo {
        name
        category
      }
      logo
      cover
      shortDescription
      marketplaceEmail
      marketplaceEmail
    }
  }
`;

export const companyPagesQuery = gql`
  query ($limit: Int!, $page: Int!, $category: String, $all: Boolean) {
    companyPages(limit: $limit, page: $page, category: $category, all: $all) {
      total
      items {
        logo
        cover
        visible
        industryId
        name
        _id
        shortDescription
      }
    }
  }
`;

export const companyPageLogoQuery = gql`
  query ($companyId: String!) {
    companyPage(companyId: $companyId) {
      logo
    }
  }
`;

export const productsCompanyQuery = gql`
  query ($companyId: String, $lang: String, $category: String) {
    productsCompany(companyId: $companyId, lang: $lang, category: $category) {
      _id
      title
      photo
      company
      published
    }
  }
`;

export const subscriptionsQuery = gql`
  query ($type: String!, $assignable: Boolean) {
    subscriptions(type: $type, assignable: $assignable)
  }
`;

export const productCompanyQuery = gql`
  query ($productId: String!, $lang: String) {
    productCompany(productId: $productId, lang: $lang) {
      _id
      title
      description
      photo
      showTracking
      lotNumberSample
      video
      productTrackingPage
      published
      lotPhoto
      gallery
      hasMarketplace
      sections
      lotPhotoDescription
      companyId
      languages
      schemas
    }
  }
`;

export const profileQuery = gql`
  query {
    profile {
      _id
      role
      firstName
      lastName
      services
      loggedAsAdmin
      photo
      company
      phone
      linkedIndustry
      email
      chains
      dockerPassword
      permissions
    }
  }
`;

export const settingsQuery = gql`
  query Settings {
    settings {
      marketplaceNotificationMail
      canSupportLogin
      mainTour
    }
  }
`;

export const getItemSummaryQuery = gql`
  query ($source: String!, $lang: String!, $filters: [SummaryFilters!]!) {
    getItemSummary(source: $source, lang: $lang, filters: $filters)
  }
`;

export const subscriptionsSessionQuery = gql`
  query ($type: String!) {
    subscriptionsSession(type: $type)
  }
`;

export const itemExplorationQuery = gql`
  query (
    $id: String!
    $directions: [GraphDirection!]!
    $startDate: DateTime
    $endDate: DateTime
    $linkedEdges: Boolean
    $lang: String
  ) {
    itemExploration(
      source: $id
      directions: $directions
      startDate: $startDate
      endDate: $endDate
      linkedEdges: $linkedEdges
      graphLang: $lang
    )
  }
`;

export const itemQuery = gql`
  query (
    $id: String
    $type: ItemFormatType!
    $version: Int
    $source: UIStatParams
    $lang: String!
  ) {
    item(id: $id, type: $type, version: $version, source: $source, lang: $lang)
  }
`;

export const searchByLotQuery = gql`
  query ($lot: String!, $product: String!, $lang: String!) {
    searchByLot(lot: $lot, product: $product, lang: $lang) {
      label
      schema
      value
    }
  }
`;

export const itemStatsFilterIdsQuery = gql`
  query ($company: String) {
    itemStatsFilterIds(company: $company) {
      id
      label
    }
  }
`;

export const itemStatsQuery = gql`
  query ($page: Int!, $limit: Int, $filters: ItemStatsFiltersInput) {
    itemStats(page: $page, limit: $limit, filters: $filters) {
      items {
        label
        id
        date
        position {
          computed
          country
          region
          city
          province
        }
      }
      total
    }
  }
`;

export const searchQuery = gql`
  query ($query: String!, $types: [String!]!, $page: Int!, $limit: Int!, $lang: String) {
    search(query: $query, types: $types, page: $page, limit: $limit, lang: $lang) {
      items {
        id
        title
        type
        abstract
      }
      total
    }
  }
`;

export const ontologySchemasQuery = gql`
  query ($lang: String, $chain: String, $fromChainPlanner: Boolean) {
    ontologySchemas(lang: $lang, chain: $chain, fromChainPlanner: $fromChainPlanner)
  }
`;

export const ontologySchemaQuery = gql`
  query ($schema: String!, $splitIO: Boolean, $lang: String!) {
    ontologySchema(uri: $schema, splitIO: $splitIO, lang: $lang)
  }
`;

// Query Payments

export const getSbSupplyChainTypesQuery = gql`
  query ($lang: String!) {
    getSbSupplyChainTypes(lang: $lang)
  }
`;

export const getItemToUploadCountQuery = gql`
  query {
    getItemToUploadCount
  }
`;

export const getUsersVisibilityForChainQuery = gql`
  query ($id: String!) {
    getUsersVisibilityForChain(chainId: $id)
  }
`;

// Query Document

export const documentServicesQuery = gql`
  query {
    documentsServices
  }
`;

export const getDocumentPricesQuery = gql`
  query {
    getPrices
  }
`;

export const documentsQuery = gql`
  query ($limit: Int = 999, $page: Int = 0, $visibility: DocumentVisibility) {
    documents(limit: $limit, page: $page, visibility: $visibility) {
      items {
        _id
        filename
        uri
        certificationName
        certificationCategory
        transactions {
          service
          transaction
        }
        createdAt
      }
      total
    }
  }
`;

export const documentsAutocompleteQuery = gql`
  query Documents {
    documentsAutocomplete {
      label
      value
    }
  }
`;

export const itemsQuery = gql`
  query (
    $limit: Int = 999
    $page: Int = 0
    $type: ItemType
    $lite: Boolean = false
    $filterBy: ItemsFilterType
  ) {
    items(limit: $limit, lite: $lite, type: $type, page: $page, filterBy: $filterBy) {
      items {
        _id
        label
        type
        transactions {
          type
        }
        uploadedAt
        uri
        createdAt
        qrCodeId
      }
      total
    }
  }
`;

export const chainsQuery = gql`
  query ($limit: Int = 999, $page: Int = 0, $purchasable: Boolean) {
    findAllChains(limit: $limit, page: $page, chainName: "", purchasable: $purchasable) {
      items {
        _id
        name
        createdAt
        userEmail
        userCanDelete
        disabledToAllUsers
        transaction
        containErrors
      }
      total
    }
  }
`;

export const getClientLimitQuery = gql`
  query {
    getClientsLimit
  }
`;

export const assignableUsersQuery = gql`
  query {
    assignableUsers {
      label
      value
    }
  }
`;

export const resourcesQuery = gql`
  query ($type: ResourceType!, $limit: Int!, $page: Int!, $enabled: Boolean, $search: String) {
    resources(type: $type, limit: $limit, page: $page, enabled: $enabled, search: $search) {
      items {
        ... on User {
          _id
          name
          enabled
          photo
          email
          lastAccess
          roles
          __typename
        }

        ... on App {
          _id
          name
          category
          createdAt
          createdByUser
          refreshTokenCounter
          enabled
          __typename
        }
        ... on Device {
          _id
          name
          enabled
          model
          typology
          lastAccess
          __typename
        }
      }
      total
    }
  }
`;

export const resourceQuery = gql`
  query ($type: ResourceType!, $id: String!) {
    resource(type: $type, id: $id) {
      ... on User {
        id
        firstName
        lastName
        gender
        fiscalCode
        companyRole
        email
        photo
        phone
        phone
        notes
        __typename
      }

      ... on Device {
        id
        model
        name
        typology
        accessToken {
          token
          expiration
        }
        refreshToken {
          token
          expiration
        }
        __typename
      }

      ... on App {
        id
        name
        category
        accessToken {
          token
          expiration
        }
        refreshToken {
          token
          expiration
        }
      }
      __typename
    }
  }
`;

export const getModulesAndChainsQuery = gql`
  query ($type: ResourceType!, $id: String!) {
    resourcePermissions(type: $type, id: $id) {
      chains {
        id
        label
        cover
      }
      modules
    }
  }
`;

export const chainNameQuery = gql`
  query ($id: String!, $lang: String!) {
    chainName(id: $id, lang: $lang)
  }
`;

export const getChainSchemasPermissionsQuery = gql`
  query ($type: ResourceType!, $id: String!, $chain: String!) {
    resourcePermissions(type: $type, id: $id) {
      categories(chain: $chain) {
        label
        shared
        items {
          id
          label
          read
          write
        }
      }
    }
  }
`;

export const getGraphFromChainQuery = gql`
  query ($id: String!) {
    getGraphFromChain(id: $id)
  }
`;

export const getClientsQuery = gql`
  query ($page: Int!, $limit: Int!, $orderField: String, $directionField: String) {
    getClients(
      page: $page
      limit: $limit
      orderField: $orderField
      directionField: $directionField
    ) {
      total
      items {
        _id
        name
        isApp
        isCurrentClient
        createdAt
      }
    }
  }
`;

export const autocompleteQuery = gql`
  query ($schemas: [String!], $external: Boolean, $internal: Boolean, $sharer: String) {
    autocompleteItems(schema: $schemas, external: $external, internal: $internal, sharer: $sharer)
  }
`;

export const accountStatsQuery = gql`
  query AccountStats {
    accountStats
  }
`;

export const purchaseBlockchainCreditQuery = gql`
  query ($creditType: CreditType!) {
    purchaseBlockchainCredit(creditType: $creditType)
  }
`;

export const cartQuery = gql`
  query {
    cart {
      name
      id
      _id
      sector
      chain
      itemCartId
    }
    cartOptions
  }
`;

export const getPostsPerPageQuery = gql`
  query ($page: Int!, $num_post: Int!, $categories: [Float!]!) {
    getPostsPerPage(page: $page, num_post: $num_post, categories: $categories)
  }
`;

export const findAllQRCodeAssetsQuery = gql`
  query QrCodes($limit: Int!, $page: Int!, $assetQuery: String!) {
    findAllQRCodeAssets(
      page: $page
      limit: $limit
      assetQuery: $assetQuery
      orderField: "createdAt"
      directionField: "desc"
    ) {
      total
      assets {
        _id
        used
        name
      }
    }
  }
`;

// ADMIN QUERY
export const usersQuery = gql`
  query ($page: Int!, $query: String) {
    users(page: $page, limit: 100, query: $query) {
      items {
        _id
        firstName
        lastName
        email
        settings {
          canSupportLogin
        }
        pending
        enabled
        role
      }
      total
    }
  }
`;

export const historyActionsQuery = gql`
  query ($id: String!, $page: Int!, $type: ResourceType!) {
    historyActions(id: $id, page: $page, limit: 10, type: $type) {
      items {
        _id
        action
        target
        extra
        createdAt
      }
      total
    }
  }
`;

export const getPlatformSettingsQuery = gql`
  query {
    getPlatformSettings {
      maintenance
    }
  }
`;

export const clientNodesQuery = gql`
  query ($page: Int, $limit: Int) {
    clientNodes(page: $page, limit: $limit) {
      total
      items {
        _id
        ip
        mcAddress
        createdAt
      }
    }
  }
`;

export const availableBlockchainClientsQuery = gql`
  query {
    availableBlockchainClients
  }
`;

export const clientConfigurationQuery = gql`
  query ($id: String!, $serviceType: ServiceType!) {
    clientNodeConfiguration(id: $id, serviceType: $serviceType) {
      wireguard
      configuration
    }
  }
`;

export const notificationsQuery = gql`
  query ($page: Int!, $limit: Int!, $after: String) {
    getNotifications(page: $page, limit: $limit, after: $after) {
      _id
      title
      type
      content
      read
      createdAt
      updatedAt
    }
  }
`;
