import { atom, getDefaultStore, useAtom } from 'jotai';
import {
  flags,
  supportedLanguages
} from '../../../Common/ChainManager/Insert/Translate/countriesData';
import i18n from 'i18next';

// This will not cause a rerender.

const languageMatch = /([^-]*-?(.*))/.exec(navigator.language);

const baseLang = (languageMatch?.[2] || languageMatch?.[1] || 'IT').toUpperCase();

export const DEFAULT_BROWSER_LANGUAGE = supportedLanguages.has(baseLang) ? baseLang : 'US';

const isDataPage = () => {
  return [
    '/page',
    '/trace-view',
    '/settings/company',
    '/settings/product',
    '/company',
    '/lot'
  ].some((it) => location.pathname.startsWith(it));
};

export const selectedLanguageAtom = atom(
  // Used in pdf.worker because localStorage is not available
  // todo fix toUpperCase
  typeof localStorage === 'undefined'
    ? undefined
    : localStorage.getItem('lang')?.toUpperCase() ?? DEFAULT_BROWSER_LANGUAGE
);

export const setLocale = (locale: string) => {
  localStorage.setItem('lang', locale);
  i18n.changeLanguage(locale).then();
  // This will update every page which use useSelectedLanguage!
  getDefaultStore().set(selectedLanguageAtom, locale);
};

export const availableLanguagesAtom = atom<(keyof typeof flags)[]>(['IT', 'US']);

export const useSelectedLanguage = () => {
  const selected = useAtom(selectedLanguageAtom, { store: getDefaultStore() })[0];
  const defaultLang = selected === 'IT' ? 'IT' : 'US';
  if (isDataPage()) {
    return supportedLanguages.has(selected!) ? selected ?? defaultLang : defaultLang;
  }
  return defaultLang;
};
