import {
  CNFlag,
  CZFlag,
  DEFlag,
  DKFlag,
  ESFlag,
  FIFlag,
  FRFlag,
  GBFlag,
  GRFlag,
  HUFlag,
  IDFlag,
  ILFlag,
  ITFlag,
  JPFlag,
  KRFlag,
  LTFlag,
  NLFlag,
  NOFlag,
  PLFlag,
  PTFlag,
  RUFlag,
  SAFlag,
  SEFlag,
  THFlag,
  TRFlag,
  TWFlag,
  UAFlag,
  USFlag
} from 'mantine-flagpack';

export const countriesData = [
  { label: 'العربية (Arabic)', value: 'AR' }, // Arabic
  { label: 'Čeština (Czech)', value: 'CS' }, // Czech
  { label: 'Dansk (Danish)', value: 'DA' }, // Danish
  { label: 'Nederlands (Dutch)', value: 'NL' }, // Dutch
  { label: 'English (British)', value: 'GB' }, // British
  { label: 'English (American)', value: 'US' }, // American
  { label: 'Suomi (Finnish)', value: 'FI' }, // Finnish
  { label: 'Français (French)', value: 'FR' }, // French
  { label: 'Deutsch (German)', value: 'DE' }, // German
  { label: 'Ελληνικά (Greek)', value: 'EL' }, // Greek
  { label: 'Bahasa Indonesia (Indonesian)', value: 'ID' }, // Indonesian
  { label: 'עברית (Hebrew)', value: 'HE' }, // Hebrew
  { label: 'Magyar (Hungarian)', value: 'HU' }, // Hungarian
  { label: '日本語 (Japanese)', value: 'JA' }, // Japanese
  { label: '한국어 (Korean)', value: 'KO' }, // Korean
  { label: 'Lietuvių (Lithuanian)', value: 'LT' }, // Lithuanian
  { label: 'Norsk (Norwegian)', value: 'NO' }, // Norwegian
  { label: 'Polski (Polish)', value: 'PL' }, // Polish
  { label: 'Português (Portuguese)', value: 'PT' }, // Portuguese
  { label: 'Русский (Russian)', value: 'RU' }, // Russian
  { label: '简体中文 (Simplified Chinese)', value: 'ZH' }, // Simplified Chinese
  { label: 'Español (Spanish)', value: 'ES' }, // Spanish
  { label: 'Svenska (Swedish)', value: 'SV' }, // Swedish
  { label: 'ไทย (Thai)', value: 'TH' }, // Thai
  { label: '繁體中文 (Traditional Chinese)', value: 'TW' }, // Traditional Chinese
  { label: 'Türkçe (Turkish)', value: 'TR' }, // Turkish
  { label: 'Українська (Ukrainian)', value: 'UK' } // Ukrainian
] as const;

export const flags = {
  IT: ITFlag,
  AR: SAFlag,
  CS: CZFlag,
  DA: DKFlag,
  NL: NLFlag,
  ID: IDFlag,
  GB: GBFlag,
  US: USFlag,
  FI: FIFlag,
  FR: FRFlag,
  DE: DEFlag,
  EL: GRFlag,
  HE: ILFlag,
  HU: HUFlag,
  JA: JPFlag,
  KO: KRFlag,
  LT: LTFlag,
  NO: NOFlag,
  PL: PLFlag,
  PT: PTFlag,
  RU: RUFlag,
  ZH: CNFlag,
  ES: ESFlag,
  SV: SEFlag,
  TH: THFlag,
  TW: TWFlag,
  TR: TRFlag,
  UK: UAFlag
};

export const supportedLanguages = new Set(Object.keys(flags));

export type LangType = keyof typeof flags;
