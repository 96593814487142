import './patch';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { initLocale } from './i18n-next';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { theme } from './theme';
import { Notifications } from '@mantine/notifications';
import { DatesProvider } from '@mantine/dates';
import i18n from 'i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import ErrorBoundary from '@/ui/ErrorBoundary';
import { queryClient } from './util/reactQuery';
import { Suspense } from 'react';
import { safeLazy } from './components/SafeLazy/SafeLazy';
import 'dayjs/locale/it';
import './index.css';
import './util/broadcast-channel';
import { initialProfile } from './hooks/useProfile';
import i18next from 'i18next';
// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';

const CookiesBanner = safeLazy(() => import('./alerts/CookiesBanner'));

const Analytics = safeLazy(() => import('./components/Analytics'));

const App = safeLazy(() => import('./App'));

const Index = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <MantineProvider theme={theme}>
          <DatesProvider settings={{ locale: i18n.language, consistentWeeks: true }}>
            <Suspense>
              <CookiesBanner />
            </Suspense>
            <Notifications limit={4} autoClose={2000} />
            <Router>
              <ErrorBoundary>
                <ModalsProvider>
                  <Suspense>
                    <Analytics />
                  </Suspense>
                  <Suspense>
                    <App />
                  </Suspense>
                </ModalsProvider>
              </ErrorBoundary>
            </Router>
          </DatesProvider>
        </MantineProvider>
      </ApolloProvider>
    </QueryClientProvider>
  );
};

// Change to sign-up component to build only the user registration page
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initLocale
  .then(async () => {
    const profile = await initialProfile();
    profile === null && i18next.loadNamespaces('content');
  })
  .then(() => root.render(<Index />));
