import { client } from '@/graphql/client';
import { profileQuery } from '@/graphql/query';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

export type UserRole = 'industry' | 'admin' | 'customer' | 'employee' | 'read_only_admin';

export interface User<TPermissions = Set<string>> {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  photo?: string;
  loggedAsAdmin?: boolean;
  chains: [];
  services: [];
  permissions: TPermissions;
  company?: string;
  phone: string;
  linkedIndustry?: string;
}

interface ProfileQuery {
  profile: User;
}

export const initialProfile = async () => {
  const { data } = await client.query<ProfileQuery>({
    query: profileQuery
  });
  return data.profile;
};

export const useProfile = () => {
  const user = usePossibleUser();

  if (!user) {
    throw new Error('User not initialized');
  }

  return user;
};

export const usePossibleUser = () => {
  const { data, loading } = useQuery<ProfileQuery>(profileQuery);
  const profile = useMemo(() => {
    if (!data?.profile) {
      return null;
    }
    return {
      ...data.profile,
      chains: data.profile.chains || [],
      services: data.profile.services || [],
      permissions: new Set(data.profile.permissions)
    };
  }, [data]);
  return loading ? undefined : profile;
};

export const useLoggedIn = () => {
  const user = usePossibleUser();
  return Boolean(user);
};

export const revalidateProfile = () => {
  client.refetchQueries({
    include: [profileQuery]
  });
};
